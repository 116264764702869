<template>
    <component
        :is="buttonComponent"
        class="button-regular cursor-pointer"
        type="button"
        :class="buttonClasses"
        :disabled="buttonDisabled"
        :to="buttonRoute"
        v-bind="buttonHref ? { href: buttonHref } : {}"
        @click="buttonClick(buttonClickParams)"
    >
        <span :class=" buttonIcon ? 'flex space-x-1.5' : ''">
            <component
                class="mt-0.5"
                v-if="buttonIcon"
                :is="buttonIcon"
                :marginAuto="false"
            ></component>
            <span>{{ buttonText }}</span>
        </span>
    </component>
</template>

<script>
export default {
    name: 'ButtonRegular',
    props: {
        buttonColor: {
            required: false,
            type: String,
            default: 'bg-zinc-100 text-black border border-zinc-200 hover:border-zinc-300 focus-visible:outline-zinc-600',
        },
        buttonDisabled: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonHref: {
            required: false,
            type: String,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonIcon: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
    },
    computed: {
        buttonComponent () {
            if (this.buttonRoute) return 'router-link';
            if (this.buttonHref) return 'a';
            return 'button';
        },
        buttonClasses () {
            const cls = {};
            cls['inline-block rounded-md p-3 shadow-sm'] = true;
            cls['cursor-pointer'] = true;
            cls['focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'] = true;
            cls['text-lg font-regular'] = true;
            cls[this.buttonColor] = true;
            cls['disabled:bg-zinc-500'] = !!this.buttonDisabled;
            return cls;
        },
    },
};
</script>
